<!--
 * @Author: your name
 * @Date: 2021-12-03 14:36:44
 * @LastEditTime: 2022-02-18 18:10:12
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\target\everyQuarter.vue
-->
<!--
按季度新建
-->
<template>
   <div class="dgdown" ref="newQuarter">
    <div class="d1">
      <dl>
        <dt>{{$t('label.first.quarter')}}</dt>
        <dd>
          <el-input v-model="newQuarterVal.oneQuarter"  onkeyup="value=value.replace(/[^\d]/g,'')"> </el-input>
        </dd>
      </dl>
        <dl>
        <dt>{{$t('label.two.quarter')}}</dt>
        <dd>
          <el-input v-model="newQuarterVal.twoQuarter"  onkeyup="value=value.replace(/[^\d]/g,'')"> </el-input>
        </dd>
      </dl>
        <dl>
        <dt>{{$t('label.three.quarter')}}</dt>
        <dd>
          <el-input v-model="newQuarterVal.threeQuarter"  onkeyup="value=value.replace(/[^\d]/g,'')"> </el-input>
        </dd>
      </dl>
        <dl>
        <dt>{{$t('label.four.quarter')}}</dt>
        <dd>
          <el-input v-model="newQuarterVal.fourQuarter"  onkeyup="value=value.replace(/[^\d]/g,'')"> </el-input>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
          newQuarterVal:{
          oneQuarter:"0",
          twoQuarter:"0",
          threeQuarter:"0",
          fourQuarter:"0",
          }
      }
  },
}
</script>

<style lang="scss" scoped>
.dgdown {
  width: 1048px;
  padding-top: 20px;
  height: 272px;
  border: 1px solid #dddbda 100%;
  .d1 {
    width: 1048px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90.2px;
    border: 1px solid #dddbda;
    dt{
          margin-left: 72px;
    }
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
      margin-top: 10px;
      margin-left: 72px;
    }
  }
}
</style>