<!-- 按年新建 -->
<template>
  <div class="dgdown" ref="newYear">
    <div class="d1">
      <dl>
        <dt>{{ yearrr }}{{ $t("year") }}</dt>
        <dd>
          <el-input
            v-model="nian"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    mryear() {
      this.yearrr = this.mryear;
    },
  },
  props: {
    mryear: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      yearrr: "",
      nian: "0",
    };
  },
  mounted() {
    this.year();
  },
  methods: {
    year() {
      this.yearrr = this.mryear;
    },
  },
};
</script>

<style lang="scss" scoped>
.dgdown {
  width: 1048px;
  padding-top: 20px;
  height: 272px;
  border: 1px solid #dddbda 100%;
  .d1 {
    width: 1048px;
    display: flex;
    align-items: center;
    height: 90.2px;
    border: 1px solid #dddbda;
    dt {
      margin-left: 72px;
    }
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
      margin-top: 10px;
      margin-left: 72px;
    }
  }
}
</style>