<template>
  <div id="table-panel" style="width: 100%">
    <!-- 表格组件 -->
    <div class="content-main" :class="{ 'open-new-line': newLine }">
      <!-- 骨架屏 -->
      <!-- <Wang-skeleton
        type="line"
        :options="skeletonOptions"
        v-if="pageNum === 1"
      >
      </Wang-skeleton> -->
      <!-- showTable -->
      <!-- v-if="pageNum !== 1" -->
      <el-table
        ref="tablePanel"
        :data="dataList"
        row-key="id"
        default-expand-all
        :tree-props="{ children: 'subTaskList', hasChildren: 'hasChildren' }"
        v-loadmore="loadMore"
        :border="border"
        :height="tableHeight"
        :row-class-name="rowClassName"
        :span-method="spanMethod"
        aria-activedescendant=""
        v-loading="pictLoading"
        :header-row-class-name="headerRowClassName"
        cell-class-name="cellClassName"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
        @select-all="selectAll"
        @selection-change="selectionChange"
        @toggleSelection="clearSelection"
        :empty-text="$t('label.dashboard.noRecord')"
        :stripe="stripe"
        :style="{ width: '100%', 'min-height': minHeight ? '418px' : '0' }"
        :header-cell-class-name="showStar ? star : ''"
      >
        <el-table-column
          v-if="checked"
          type="selection"
          :resizable="false"
          :selectable="checkboxSelect"
          width="51"
        >
        </el-table-column>
        <!-- 表头&内容展示区 -->
        <template v-for="(item, itemIndex) in tableAttr">
          <slot :name="item.schemefieldName" :item="item">
            <!-- 普通文本类型数据 -->
            <el-table-column
              align="left"
              :label="item.nameLabel"
              :prop="item.schemefieldName"
              :sortable="item.sortable"
              :show-overflow-tooltip="true"
            >
              <template slot="header" v-if="customHeader">
                <div class="tableHeadField">
                  <span style="label">
                    {{ namaLableList[itemIndex] }}
                  </span>
                </div>
              </template>
              <template slot-scope="scope">
                <!-- 达成列表下除目标，创建人，负责人，需要计算百分比 -->
                <div
                  v-if="
                    tabPosition === 'reach' &&
                    item.schemefieldName !== 'createname' &&
                    item.schemefieldName !== 'targetname' &&
                    item.schemefieldName !== 'targetOwner'
                  "
                >
                  <p style="margin: 0">
                    {{
                      scope.row[item.schemefieldName] &&
                      scope.row[item.schemefieldName].split(",")[0]
                    }}
                  </p>
                  <p style="margin: 0">
                    {{
                      scope.row[item.schemefieldName] &&
                      scope.row[item.schemefieldName].split(",")[1]
                    }}
                  </p>
                  <p
                    style="margin: 0; height: 17px; line-height: 17px"
                    v-if="issetting"
                  >
                    <span
                      style="font-size: 12px; color: #e2403c"
                      v-if="
                        getDecimal(
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(',')[1],
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(',')[0]
                        ) < inputFirst
                      "
                    >
                      {{
                        getPercent(
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(",")[1],
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(",")[0]
                        )
                      }}
                    </span>
                    <span
                      style="font-size: 12px; color: #4bca82"
                      v-else-if="
                        inputBai <
                        getDecimal(
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(',')[1],
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(',')[0]
                        )
                      "
                    >
                      {{
                        getPercent(
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(",")[1],
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(",")[0]
                        )
                      }}
                    </span>
                    <span style="font-size: 12px; color: #006dcc" v-else>
                      {{
                        getDecimal(
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(",")[1],
                          scope.row[item.schemefieldName] &&
                            scope.row[item.schemefieldName].split(",")[0]
                        )
                      }}
                    </span>
                  </p>
                  <p
                    style="
                      margin: 0;
                      height: 17px;
                      line-height: 17px;
                      font-size: 12px;
                    "
                    v-else
                  >
                    {{
                      getPercent(
                        scope.row[item.schemefieldName] &&
                          scope.row[item.schemefieldName].split(",")[1],
                        scope.row[item.schemefieldName] &&
                          scope.row[item.schemefieldName].split(",")[0]
                      )
                    }}
                  </p>
                </div>
                <!-- 目标列表下除目标，创建人，负责人，需要计算内联编辑 -->
                <div
                  v-else-if="
                    tabPosition === 'target' &&
                    item.schemefieldName !== 'createname' &&
                    item.schemefieldName !== 'targetname' &&
                    item.schemefieldName !== 'targetOwner'
                  "
                >
                  <!-- 行内编辑 -->
                  <el-input
                    v-if="
                      (inputEdit &&
                        scope.row.edit &&
                        currentColumn == scope.column.label) ||
                      (inputEdit && scope.row.edit && editAllBtn)
                    "
                    ref="inputEdit"
                    v-model="scope.row[item.schemefieldName]"
                    size="mini"
                  ></el-input>
                  <!-- 正常显示内容 -->
                  <div v-else>
                    <span class="cannotEdit">
                      {{ scope.row[item.schemefieldName] }}
                    </span>
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      @click="editCell(item, scope.row, scope.column)"
                      style="
                        position: absolute;
                        right: 10px;
                        top: 3px;
                        display: none;
                        cursor: pointer;
                        height: 12px;
                      "
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </div>
                </div>
                <div v-else>
                  <span>
                    {{ scope.row[item.schemefieldName] }}
                  </span>
                </div>
              </template>
            </el-table-column>
          </slot>
        </template>
        <!-- 操作列 -->
        <el-table-column label="操作" v-if="isShowOpration">
          <template slot-scope="scope">
            <span v-for="(item, index) in oprationBtnList" :key="index">
              <el-button
                v-if="scope.row.isEnabled != item.label"
                size="small"
                type="text"
                style="marginright: 8px"
                @click="handleOperationBtn(scope, item)"
              >
                {{ item.label }}
              </el-button>
            </span>
          </template>
        </el-table-column>
        <slot></slot>
      </el-table>

      <!-- 表格内联编辑 -->
      <el-dialog
        :title="$t('label.change')"
        :visible.sync="dialogVisible"
        top="15%"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <p
          class="pull-left"
          style="
            height: 30px;
            line-height: 30px;
            margin-right: 10px;
            margin-bottom: 0;
          "
        >
          {{ field }}
        </p>
        <editable-cell
          ref="editableCell"
          :field="field"
          :schemefieldLength="schemefieldLength"
          :options="editableCellOptions"
          :value="toChangeValue"
          :isFilter="false"
          :input-type="inputType"
          :err-tip="errTip"
          origin-type="table"
          width="95%"
        >
        </editable-cell>
        <p
          style="text-align: left; padding-top: 10px; clear: both"
          v-if="showApply2Others"
        >
          <!-- 是否应用于选中数据 -->
          <el-checkbox v-model="changeAll">{{
            $t("vue_label_commonobjects_view_change_all_selected_records")
          }}</el-checkbox>
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button type="primary" size="mini" @click="saveChange">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { emptyArray } from "@/utils/array";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import * as Target from "./api.js";

export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: {
    EditableCell,
  },
  props: {
    // 是否展示操作按钮
    isShowOpration: {
      type: Boolean,
      default: false,
    },
    // 操作按钮列表
    oprationBtnList: {
      type: Array,
      default: () => [],
    },
    // 行内编辑input
    inputEdit: {
      type: Boolean,
      default: false,
    },
    // 编辑全部
    editAllBtn: {
      type: Boolean,
      default: false,
    },
    // 显示星号
    showStar: {
      type: Boolean,
      default: false,
    },
    inputBai: {
      type: String,
    },
    inputFirst: {
      type: String,
    },
    // 表头属性自定义
    fieldName: {
      type: String,
      default: "",
    },
    // 使用自定义表头
    customHeader: {
      type: Boolean,
      defalt: true,
    },
    // 页码
    pageNum: {
      type: Number,
      defalt: 1,
    },
    // 加载标识
    pictLoading: {
      type: Boolean,
      defalt: false,
    },
    // 每一列数据的 id
    id: {
      type: String,
      default: "id",
    },
    // 是否条纹状表格
    stripe: {
      type: Boolean,
      default: false,
    },
    // 当前视图是否可编辑
    viewModify: {
      type: String,
    },
    // 是否提示无更多数据
    noDataTip: {
      type: Boolean,
      default: false,
    },
    // 是否开启分页
    pagination: {
      type: Boolean,
      default: true,
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
    // 表格数据
    pageObj: {
      type: Object,
      default: null,
    },
    // 可跳转链接是否使用a标签
    useHyperlinks: {
      type: Boolean,
      default: true,
    },
    // 是否展示编辑表格字段
    showTableHeadTool: {
      type: Boolean,
      default: true,
    },
    // 表头
    tableAttr: {
      type: Array,
      default: null,
    },
    // 数据排序
    sortable: {
      type: Boolean,
      default: false,
    },
    // 选择框
    checked: {
      type: Boolean,
      default: false,
    },
    // 按钮
    tableButton: {
      // 按钮
      type: [Array, Object],
      default: () => [],
    },
    // 操作按钮控制函数
    tableButtonCtrl: {
      type: Function,
      default: undefined,
    },
    // 表格高度
    tableHeight: {
      type: [String, Number, undefined],
      default: undefined,
    },
    // 是否开启边框
    border: {
      type: Boolean,
      default: false,
    },
    // 选中数量
    checkedCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    // 是否开启序号
    showIndex: {
      type: Boolean,
      default: true,
    },
    // 单选框是否可以选中
    selectable: {
      type: [Function, undefined],
      default: undefined,
    },
    selectedList: {
      type: Array, // 已选中列
      default: () => [],
    },
    spanMethod: {
      // 合并列算法
      type: [Function, undefined],
      default: undefined,
    },
    // 是否开启分页记忆选中功能 现版本配合vuex使用
    memory: {
      type: Boolean,
      default: false,
    },
    headerRowClassName: {
      type: [Function, String],
      default: undefined,
    },
    rowClassName: {
      type: [Function, undefined],
      default: undefined,
    },
    // 是否展开新行
    newLine: {
      type: Boolean,
      default: false,
    },
    // 是否设置min-height
    minHeight: {
      type: Boolean,
      default: false,
    },
    // 使用父组件传来的objectApi，父组件使用路由传递的值
    objectApi: {
      type: String,
      default: "",
    },
    // 是否显示锁定功能
    showLock: {
      type: Boolean,
      default: true,
    },
    //判断是目标还是达成
    tabPosition: {
      type: String,
      default: "",
    },
    //是否展示达成百分比颜色
    issetting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    // this.memoryCheckList = [] // 记忆分页选中列表
    return {
      currentColumn: "", // 编辑时当前列
      num: false,
      skeletonOptions: {
        active: true,
        row: 18,
        column: 5,
        width: "18vw",
        height: "20px",
        justifyContent: "space-around",
      },
      schemefieldLength: 0, // 评分字段最大分值
      errTip: this.$i18n.t("label_vlidaterule_message"),
      field: "", // 要修改的字段名称
      changeAll: false, // 是否应用于全部选中数据
      editableCellOptions: [],
      inputType: "", // 要修改数据显示类型
      toChangeValue: null, //要修改的数据
      dialogVisible: false,
      editModeEnabled: false,
      dataList: this.pageObj.dataList,
      memoryCheckList: [],
      pageSize: [10, 20, 50, 100],
      showTable: false,
      pageInfo: true,
      checkedList: [],
      selected: [],
      selection: [],
      total: 0, // 数据总条数
      showApply2Others: false,
      namaLableList: [],
      editableCellAttr: null, //单元格编辑字段信息
      dataId: "",
      token: this.$CCDK.CCToken.getToken(),
      inlineedit: false, // 全局内联编辑权限
      index: 1,
    };
  },
  created() {
    this.memoryCheckList = this.memoryCheckList.concat(this.selectedList);
    // 遍历索引，赋值给data数据
    // this.dataList.forEach((item, index) => {
    //   item.index = index
    // })
    this.inlineedit = localStorage.getItem("inlineedit");
  },
  mounted() {
    // 如果表格宽度小于847  分页部分将折行显示 逻辑控制隐藏左边部分
    if (document.getElementById("table-panel").offsetWidth < 847) {
      this.pageInfo = false;
    }
    this.showTable = true;
  },
  methods: {
    //star函数中指定序列返回star类名
    star(obj) {
      if (
        obj.column.label == "企业微信部门名称" ||
        obj.column.label == "企业微信部门ID" ||
        obj.column.label == "企业微信账号"
      ) {
        return "star";
      }
    },
    // 操作按钮
    handleOperationBtn(scope, item) {
      if (item.action == "Binding") {
        this.$emit("Binding", scope.row);
      } else if (item.action == "Save") {
        this.$emit("Save", scope.row);
      } else if (item.action == "Cancel") {
        this.$emit("Cancel", scope.row);
      } else if (item.action == "Rebind") {
        this.$emit("Rebind", scope.row);
      } else if (item.action == "Unbind") {
        this.$emit("Unbind", scope.row);
      } else if (item.action == "AccountBinding") {
        this.$emit("AccountBinding", scope.row);
      }
    },
    //计算百分比
    getPercent(num, total) {
      total = parseInt(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0
        ? "0%"
        : Math.round((num / total) * 10000) / 100.0 + "%";
    },
    //计算百分小数
    getDecimal(num, total) {
      total = parseInt(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0 ? 0 : Math.round((num / total) * 10000) / 100.0;
    },
    checkboxSelect(row) {
      if (
        row.their_task &&
        window.location.href.substring(window.location.href.length - 3) == "p03"
      ) {
        return false; // 禁用
      } else {
        return true; // 不禁用
      }
    },
    // 强制刷新
    changeTableAttr(tableAttr) {
      this.namaLableList = [];
      tableAttr.forEach((item) => {
        this.namaLableList.push(item.nameLabel);
      });
      this.$nextTick(() => {
        this.$refs.tablePanel.doLayout();
        this.$forceUpdate();
      });
    },
    // 显示编辑按钮
    cellMouseEnter(row, column, cell) {
            // 判断是否开启内联编辑
      if(this.$store.state.userInfoObj.enableInlineEdit==='false') return;
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
      //目标 负责人  创建人
      if (
        attr[0] &&
        attr[0].nameLabel !== this.$i18n.t("label.htyj.mubiao") &&
        attr[0].nameLabel !== this.$i18n.t("label_person_in_charge") &&
        attr[0].nameLabel !== this.$i18n.t("label.createname")
      ) {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
          : "";
      }
    },
    // 隐藏编辑按钮
    cellMouseLeave(row, column, cell) {
            // 判断是否开启内联编辑
      if(this.$store.state.userInfoObj.enableInlineEdit==='false') return;
      cell.getElementsByClassName("editIconImg")[0]
        ? (cell.getElementsByClassName("editIconImg")[0].style.display = "none")
        : "";
    },
    // 表格滚动懒加载
    append() {
      // this.loading = true
      this.$emit("append");
      // this.loading = false
      // this.loading = true
      // this.$http[this.url]({
      //   ...params,
      //   offset: this.offset,
      //   limit: this.limit
      // })
      //   .then(result => {
      //     this.tableData = [
      //       ...this.tableData,
      //       ...result.data.rows
      //     ]
      //
      //     this.total = result.data.total || 0
      //   })
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
    reload() {
      // 刷新重置 offset 和表格
      this.params.offset = 0;
      this.tableData = [];
      // api动态加载完 开始重新请求数据
      this.$nextTick(() => {
        this.init(this.params);
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动事件
    loadMore() {
      let that = this;
      // let dom = document.querySelector('.el-table__body-wrapper')
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        // 解决表格滚动时左右对不齐问题
        if (scrollDistance <= 30) {
          that.$refs.tablePanel.doLayout();
        }
        if (scrollDistance !== 0) {
          //等于0证明已经到底，可以请求接口
          if (!this.noDataTip) {
            that.fnThrottle(that.append, 500, 1000)();
          }
        }
      });
    },
    // 表格滚动懒加载
    clearSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.tablePanel.toggleRowSelection(row);
        });
      } else {
        this.$refs.tablePanel.clearSelection();
      }
    },
    // 编辑单元格
    editCell(item, row, column) {
      // 阻止内联编辑按钮冒泡事件
      event.preventDefault();
      this.editableCellAttr = item;
      this.editableCellAttrr = row;
      // 点击单元格内编辑图标时不跳转
      this.showApply2Others = this.checkedList.length > 1 ? true : false;
      this.inputType = "";
      this.dataId = row.id;
      this.changeAll = false;
      this.editableCellOptions = [];
      this.field = item.nameLabel;
      this.inputType = "number";
      this.toChangeValue = {
        value:
          row[item.schemefieldName] === undefined ||
          row[item.schemefieldName] === null
            ? 0
            : row[item.schemefieldName].replace(/,/g, ""),
      };
      if (this.inputEdit) {
        this.currentColumn = column.label; // 编辑列复制当前列
        this.$emit("inLineEdit", true);
      } else {
        this.dialogVisible = true;
      }
      // this.$nextTick(()=>{
      // 

      // this.$refs.editableCell && this.$refs.editableCell.setInputValue()
      // })
      //   } else {
      //     this.$message.warning("抱歉，您无权编辑该条数据，请联系系统管理员。");
      //   }
      // });
    },
    // 保存修改字段
    saveChange() {
      let datas = [];
      // TODO:查找和查找多选类型字段值修改
      let value = this.$refs.editableCell.editValue;
      //单选数据
      if (this.checkedList.length > 1 && this.changeAll) {
        this.checkedList.forEach((item) => {
          for (var key in item.periodList) {
            if (
              item.periodList[key][this.editableCellAttr.schemefieldName] !==
              undefined
            ) {
              let editId =
                item.periodList[key][this.editableCellAttr.schemefieldName];
              let data = { id: editId.split("-")[0] };
              data["targetvalue"] = value.toString();
              datas.push(data);
            }
          }
        });
      } else {
        for (var key in this.editableCellAttrr.periodList) {
          if (
            this.editableCellAttrr.periodList[key][
              this.editableCellAttr.schemefieldName
            ] !== undefined
          ) {
            let editId =
              this.editableCellAttrr.periodList[key][
                this.editableCellAttr.schemefieldName
              ];
            let data = { id: editId.split("-")[0] };
            data["targetvalue"] = value.toString();
            datas.push(data);
          }
        }
      }
      // 最多应用于200条数据
      if (datas.length > 200) {
        this.$i18n.t("vue_label_commonobjects_view_up_to_data");
      } else {
        let params = {
          jsonStr: JSON.stringify(datas),
          target:this.editableCellAttrr.target,
        };
        //输入值为空时不可保存
        if (value.toString().trim() != "") {
          Target.UpdateTargetValue(params).then(() => {
            this.dialogVisible = false;
            this.$parent.getTable();
            this.checkedList = [];
            this.$message.success(
              this.$i18n.t("vue_label_notice_change_success")
            );
          });
        } else {
          this.$message.warning(
            // "提示输入不可为空"
            this.$i18n.t("label.value.notnull")
          );
        }
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 内联编辑内容改变
    handleChange() {
      this.$emit("handleChange", ...arguments);
    },
    // 全选事件
    selectAll(selection) {
      this.$emit("onchange", selection);
      // 如果全选 将全部列表元素插入到已选列表 否则全部删除列表
      let copyArr = selection;
      // selection = this.pageObj.dataList
      selection = this.dataList;
      selection.forEach((row) => {
        this.handlerChecked(row, !emptyArray(copyArr));
      });
      this.checkedList = selection;

      this.$emit("selectAll", selection, this.memoryCheckList);
      this.handleSelect(selection);
    },
    handleSelect(val) {
      const hasSave = this.selected.find((item) => {
        return item.page === this.pageObj.page;
      });
      if (hasSave) {
        // hasSave.rows = this.pageObj.dataList.filter(item => {
        //   return val.includes(item)
        // })
        hasSave.rows = this.dataList.filter((item) => {
          return val.includes(item);
        });
      } else {
        this.selected.push({
          page: this.pageObj.page,
          rows: val,
        });
      }
    },
    // 处理全选反选中的冗余代码
    handlerChecked(row, isNotAllCheck) {
      if (row.id) {
        let id = row.id;
        let idx = this.memoryCheckList.indexOf(id);
        // 如果不存在 加入
        if (idx === -1 && !isNotAllCheck) {
          this.memoryCheckList.push(id);
        }
        // 如果存在 直接删除
        if (idx > -1 && isNotAllCheck) {
          this.memoryCheckList.splice(idx, 1);
        }
      }
    },
    sortBy(props) {
      return function (a, b) {
        return a[props] - b[props];
      };
    },
    // 表格选中状态
    selectionChange(rows) {
      this.checkedList = rows;
      this.$emit("onchange", this.checkedList);
    },
    // 获取被选中列表
    getCheckedList() {
      // 如果开启分页记录 memorycheckList中的所有数据
      if (this.memory) {
        // 将当前页的数据保存 memorycheckList中 防止bug
        return this.memoryCheckList;
      } else {
        return this.checkedList;
      }
    },
    clearMemoryList() {
      this.memoryCheckList = [];
    },
    toggleSelection() {
      // if (this.memory) {
      //   this.$refs['tablePanel'].clearSelection()
      //   const target = this.selected.find(item => {
      //     return item.page === this.pageObj.page
      //   })
      //   if (!target) return
      //   const rows = target.rows
      //   if (rows && rows.length > 0) {
      //     this.$nextTick(() => {
      //       rows.forEach(row => {
      //         this.dataList.forEach(item => {
      //           if (item.memId === row.memId) {
      //             this.$refs['tablePanel'].toggleRowSelection(item)
      //           }
      //         })
      //       })
      //     })
      //   }
      // }
      // if (rows) {
      //   rows.forEach((row) => {
      //     this.$refs.tablePanel.toggleRowSelection(row);
      //   });
      // } else {
      //   this.$refs.tablePanel.clearSelection();
      // }
    },
  },
  watch: {
    pageObj: {
      // 分页数据发生改变时候选中选项框
      handler: function (oldVal, newVal) {
        // 定时器确保表格已经刷新
        setTimeout(() => {
          // 数据更新
          this.dataList = this.pageObj.dataList;
          this.dataList &&
            this.dataList.forEach((item, index) => {
              item.index = index;
            });
          // 打印传递数据
          // Bus.$emit('printList',this.dataList)
          this.$nextTick(() => {
            //解决第一次触发emit无效问题
            this.$store.state.printList = this.dataList;
          });
          // 创建选中
          if (!newVal || !newVal.dataList) {
            return false;
          }
          // 记录上次选中数据
          // TODO:修改选中之前的数据状态
          newVal.dataList.forEach((item) => {
            if (item.checked !== undefined) {
              this.$refs.tablePanel.toggleRowSelection(item);
            }
          });
        }, 20);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.content-main {
  position: relative;
  clear: both;
}
.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}
.action-btn {
  color: #006dcc !important;
}
// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}
.stars span {
  font-size: 20px;
  margin-left: 3px;
}
.stars span:after {
  content: "☆";
}
.stars .show:after,
.stars .show2:after {
  content: "★";
}
.show2 {
  color: #f7ba2a;
}
::v-deep .el-table__header {
  border-bottom: 1px solid #dedcda;
}
.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
  }
  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }
  .dragBox {
    width: 49%;
  }
  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;
    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;
      i {
        position: absolute;
        z-index: 100;
      }
      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }
  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }
  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }
  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;
    &:hover {
      background: #fafaf9;
    }
  }
  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}
// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 14px;
}
.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}
::v-deep .el-dialog__header {
  text-align: left;
}
::v-deep .el-table-column--selection .cell {
  text-align: center;
}
// ::v-deep .el-table__fixed-body-wrapper {
//   // top: 48px !important;
// }
::v-deep .el-dialog__footer {
  clear: both;
}
// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}
.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;
  .popover-span {
    position: absolute;
    right: 0;
  }
}
// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}
// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}
::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #9e9893;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}
::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}
::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}
::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}
::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}
::v-deep .el-table td .cell {
  padding-right: 20px;
}
* {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}
// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }
  tr th {
    background: #fafaf9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  th .cell {
    padding-right: 14px;
    .el-checkbox {
      margin-left: 0;
    }
  }
}
.admin_s {
  margin-top: -4px;
  cursor: pointer;
  width: 14px;
}
::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
}
::v-deep .el-table--border td {
  border-right: none;
}
.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .editableCell {
  .el-input-number .el-input__inner {
    height: 30px !important;
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
<style>
/* 表头加星号 */
.el-table th.star > .cell:before {
  content: "*";
  color: #ff1818;
}
</style>
