
//目标
import axios from '@/config/httpConfig'

// 展示已启用的目标模型列表
export function ListEnableModel (data) {
  return axios.post('/target/listEnableModel', data)
}

// 展示目标列表
export function ShowTargetList (data) {
  return axios.post('/target/showTargetList', data)
}
// 展示达成列表
export function TargetConclude (data) {
  return axios.post('/target/showSummaryList', data)
}
// 修改目标值
export function UpdateTargetValue (data) {
  return axios.post('/target/updateTargetValue', data)
}

// 查询会计年度列表
export function QueryFiscalyearList (data) {
  return axios.post('/target/queryFiscalyearList', data)
}
// 展示颜色设置
export function showColorSetting(data){
  return axios.post("/target/showColorSetting",data)
}
//  保存颜色设置
export function saveColorSetting(data){
  return axios.post("/target/saveColorSetting",data)
}
// 删除目标
export function DeleteTarget (data) {
  return axios.post('/target/deleteTarget', data)
}
//新建目标
export function SaveTarget (data) {
  return axios.post('/target/saveTarget', data)
}
//新建汇总
export function saveSummary (data) {
  return axios.post('/target/saveSummary', data)
}
//通过选择的角色id查人员
export function assignUserByCondition (data) {
  return axios.post('/target/assignUserByCondition', data)
}
//计算目标值
export function calTargetValue (data) {
  return axios.post('/target/calTargetValue', data)
}
// 查看目标模型详情
export function ModelDetail (data) {
  return axios.post('/target/modelDetail', data)
}
