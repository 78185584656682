<!-- author：王雪茹 "modelList && modelList.length > 0  -->
<template>
  <div class="targetmain">
    <div class="main" v-if="modelList && modelList.length > 0">
      <div class="header">
        <div class="headerup">
          <div class="hupleft">
            <ChooseView
              :object-name="tabName"
              :view-name="modelName"
              :view-list="modelList"
              :viewId="modelId"
              :targetImg="targetImg"
              v-model="inpName"
              :targetFlag="true"
              @changeView="changeView"
            ></ChooseView>
          </div>
          <div class="hupright">
            <!-- 新建客户目标按钮 新建客户目标-->
            <el-button
              type="mini button"
              class="customerBtn"
              @click="createcustarr()"
            >
              {{ newBtnName }}
            </el-button>

            <!-- 新建汇总目标按钮 -->
            <el-button
              type="mini button"
              class="summaryBtn"
              @click="dialogVisiblee"
              >{{ $t("label.new.collect.target") }}</el-button
            >

            <!-- 新建客户目标dialog -->
            <el-button type="mini button" class="lastBtn" @click="batchDelete"
              >{{ $t("label.batchDelete") }}
            </el-button>
          </div>
        </div>

        <div class="headerdown">
          <div class="hdownleft" style="height: 30px">
            <el-radio-group
              v-model="tabPosition"
              stretch="true"
              size="mini"
              @change="radioGroupBtn"
            >
              <!-- 目标 -->
              <!-- 达成 -->
              <el-radio-button label="target">{{
                $t("label.htyj.mubiao")
              }}</el-radio-button>
              <el-radio-button label="reach">{{
                $t("label_reach")
              }}</el-radio-button>
            </el-radio-group>
            <!-- 年度 -->
            <span class="label_s">{{ $t("label_year") }}</span>
            <el-select
              v-model="targetYear"
              placeholder=""
              size="mini"
              class="searchBox"
              @change="targetYearChange"
            >
              <el-option
                v-for="item in yearOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- 目标类型 -->
            <span class="label_s">{{ $t("label_target_type") }}</span>
            <el-select
              v-model="typeValue"
              size="mini"
              class="searchBox"
              @change="typeOptionsChange"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- 目标 -->
            <span class="label_s">{{ $t("label.htyj.mubiao") }}</span>
            <el-input
              v-model="targetKeyword"
              style="width: 120px"
              size="mini"
              class="searchBox"
              @change="targetChange"
            ></el-input>
            <!-- 负责人 -->
            <span class="label_s">{{ $t("label_person_in_charge") }}</span>
            <!-- <el-select
              :placeholder="$t('label.searchs')"
              class="no-suffix"
              filterable
              clearable
              v-model="targetowner"
              @keyup.enter.native="ownerEvent"
              @clear="clear"
            >
              <p class="searchTipBox">
                <span
                  @click="ownerSerch()"
                  style="display: inline-block; width: 100%"
                >
                  <i class="el-icon-search"></i>
                  <span style="padding-left: 10px">
                    <! 前往精准搜索 -->
            <!-- {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                  </span>
                </span>
              </p>
              <el-option
                v-for="item in ownerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option> -->
            <!-- </el-select> -->
            <el-input
              v-model="targetowner"
              class="searchBox"
              size="mini"
              @change="ownerSerch"
            >
            </el-input>
          </div>

          <div class="hdownright">
            <!-- 问号 -->
            <el-popover
              placement="bottom-start"
              :title="$t('lable.goal.description') + ':'"
              width="268"
              trigger="hover"
              class="btnRef"
            >
              <el-button
                slot="reference"
                size="mini"
                class="tips_box"
                style="margin: 0"
                @mouseenter.native="mouseEnterMark"
                @mouseleave.native="mouseLeaveMark"
              >
                <svg class="icon" aria-hidden="true">
                  <use :href="'#icon-' + img"></use>
                </svg>
              </el-button>
              <!-- 弹框显示 -->
              <div>
                <span>{{ descriptionName ? descriptionName : "无" }}</span>
              </div>
            </el-popover>
            <!-- 设置  -->
            <el-popover
              ref="setting"
              placement="bottom"
              popper-class="btnRl"
              width="381"
              class="btnRight"
              trigger="hover"
              @show="showset"
              @hide="hideset"
            >
              <el-button
                slot="reference"
                size="mini"
                class="setning"
                v-show="tabPosition === 'reach'"
                @mouseenter.native="seting"
                @mouseleave.native="sett"
              >
                <i>
                  <svg class="icon" aria-hidden="true">
                    <use :href="'#icon-' + setting"></use>
                  </svg>
                </i>
              </el-button>
              <div class="set_content">
                <el-checkbox v-model="issetting">{{
                  $t("label.color.display.of.completion.rate")
                }}</el-checkbox>
                <ul class="colorBai">
                  <li style="margin-right: 15px">0%</li>
                  <li style="margin-right: 15px">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-jiantou"></use>
                    </svg>
                  </li>
                  <li style="margin-right: 15px">
                    <el-input
                      v-model="inputFirst"
                      size="mini"
                      ref="minColor"
                      :value="inputFirst"
                      @change="sumchange"
                    ></el-input
                    >%
                  </li>
                  <li style="margin-right: 15px">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-jiantou"></use>
                    </svg>
                  </li>
                  <li>
                    <el-input
                      v-model="inputBai"
                      size="mini"
                      ref="maxColor"
                      :value="inputBai"
                      @change="saveChange"
                    ></el-input
                    >%
                  </li>
                  <li style="margin: 0 15px">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-jiantou"></use>
                    </svg>
                  </li>
                  <li>{{ $t("lable.even.higher") }}</li>
                </ul>
                <p class="progress-color">
                  <span class="lf"></span>
                  <span class="center_col"></span>
                  <span class="rf"></span>
                </p>
                <div class="content_butts">
                  <el-button size="small" @click="saveColorSetting('reset')">{{
                    $t("lable.reset")
                  }}</el-button>
                  <el-button
                    size="small"
                    type="primary"
                    @click="saveColorSetting('save')"
                    >{{ $t("label_chatter_save") }}</el-button
                  >
                </div>
              </div>
            </el-popover>
            <!-- 刷新 -->
            <el-button
              size="mini button"
              class="refreshBtn"
              v-show="tabPosition === 'reach'"
            >
              <i @click="refreshTable">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-refresh"></use>
                </svg>
              </i>
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <table-panel
        v-if="tableAttr && tableAttr.length > 0"
        class="text-center"
        ref="tablePanel"
        :border="true"
        :table-height="tableHeight"
        :show-index="true"
        :checked="tabPosition === 'target' ? true : false"
        :customHeader="true"
        :obj-id="objId"
        :object-api="objectApi"
        :view-id="modelId"
        :pageNum="page"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :noDataTip="noDataTip"
        :pict-loading="pictLoading"
        :tabPosition="tabPosition"
        :fieldName="fieldName"
        :inputBai="inputBai"
        :inputFirst="inputFirst"
        :issetting="issetting"
        @sumchange="sumchange"
        @saveChange="saveChange"
        @append="append"
        @onchange="onchange"
        @refresh="refreshTable"
      >
      </table-panel>
      <div
        v-else
        class="no_table"
        :style="{ width: '100%', height: tableHeight + 'px' }"
      >
        <div class="content">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-queshengye"></use>
          </svg>
          <!-- 请先创建一条目标记录。 -->
          <div>{{ $t("label.please.create.a.target.record.first.") }}</div>
        </div>
      </div>
    </div>
    <div class="main_no" v-else>
      <div class="content">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-queshengye"></use>
        </svg>
        <!-- 无可用目标模型。请先创建目标模型，也可联系管理者或系统管理员。 -->
        <div>{{ $t("label.no.target.model.available.") }}</div>
      </div>
    </div>
    <!-- 新建目标汇总弹框 -->
    <newCustomerTarget
      :newYear="newYear"
      :newMonth="newMonth"
      :newQuarter="newQuarter"
      :createcustar="createcustar"
      :Mmodelid="Mmodelid"
      :analog="analog"
      :objconid="objconid"
      :tarPrincipal="tarPrincipal"
      :targetobj="targetobj"
      @custTargetCancel="custTargetCancel"
      @custTargetConfirm="custTargetConfirm"
    />
    <!-- 新建汇总目标dialog -->
    <newCollectTarget
      :newYear="newYear"
      :newMonth="newMonth"
      :newQuarter="newQuarter"
      :Mmodelid="Mmodelid"
      :objconid="objconid"
      :dialogVisible="dialogVisible"
      :analog="analog"
      :targetobj="targetobj"
      :tarPrincipal="tarPrincipal"
      @collectTargetCancel="collectTargetCancel"
      @collectConfirm="collectConfirm"
    />
    <!-- 批量删除 -->
    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="closeDialogVisible"
      :title="$t('label.delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 14px; color: rgb(8, 7, 7)">
        <!-- 确认删除选中的{{ tableCheckedObjectArr.length }}条数据吗？ -->
        {{
          $t("vue_label_pool_deleteconfirm", [tableCheckedObjectArr.length])
        }}？
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmDel" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>

    <!--查找字段弹框-->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <div
        :style="{
          'min-height': dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :dialogBodyHeight="dialogBodyHeight"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          :modelid="relevantmodelid"
          :prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {ChooseView } from "@/components/index";
import newCustomerTarget from "./components/newCustomerTarget.vue";
import TablePanel from "./tablePanel.vue";
import * as Target from "./api.js";
import newCollectTarget from "./components/newCollectTarget.vue";
import * as TargetModel from "./api.js";

export default {
  components: {
    ChooseView,
    newCustomerTarget,
    newCollectTarget,
    TablePanel,
  },
  data() {
    return {
      relevantObjId: "user",
      relevantPrefix: "005",
      relevantmodelid: "",
      // ownerList: [{}],
      showSearchTable: false,
      fieldName: "schemefieldName", // 目标和达成表头属性不同
      inputBai: "80",
      inputFirst: "60",
      issetting: false,
      targetImg: "target_model",
      inpName: "",
      createcustar: false,
      dialogVisible: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      closeDialogVisible: false,
      img: "helpp",
      setting: "sett",
      tabPosition: "target",
      modelList: [],
      modelId: "",
      tableCheckedObjectArr: [],
      modelName: "",
      tableHeight: "300px",
      noDataTip: false,
      pictLoading: false,
      pageObj: {
        dataList: [],
      },
      tableAttr: [],
      //目标
      defaultTableAttr: [
        {
          nameLabel: this.$i18n.t("component.salesperformance.label.target"), //"目标"
          schemefieldName: "targetname",
        },
        {
          nameLabel: this.$i18n.t("label_person_in_charge"), //负责人
          schemefieldName: "targetOwner",
        },
        {
          nameLabel: this.$i18n.t("label.tag.manage.createdby"), //创建人
          schemefieldName: "createname",
        },
      ],
      objectApi: "",
      objId: "",
      page: 1, //页码默认为1
      pageSize: 50, // 每页展示数据条数
      yearOptions: [],
      tabName: this.$i18n.t("component.salesperformance.label.target"), //"目标",
      descriptionName: "", //目标描述展示内容
      typeOptions: [], //目标类型
      targetKeyword: "", //搜索关键词
      targetowner: "", //目标负责人
      typeValue: "",
      tarPrincipal: true,
      reachList: [],
      // delContent: this.$i18n.t("label.weixin.confirm.delete"), //删除提示语
      Mmodelid: "", //模型id
      targetobj: "", //目标类型
      analog: "", //模型基准对象
      targetYear: "",
      selectYear: "",
      selectType: "",
      newYear: false, //按年新建
      newMonth: false, //按月新建
      newQuarter: false, //按季新建
      targetid: "",
      target: "",
      colorId: "",
      present: "",
      ifFresh: "false", //是否刷新
      // targetname: "",
      targetSzName: "", //目标所在表名
      newBtnName: "", //新建按钮名称
      objconid: "",
    };
  },
  watch: {
    serarchForeTo(newvalue) {
      if (newvalue) {
        this.$nextTick(() => {
          this.$refs.searchTable.page = 1;
          this.$refs.searchTable.pageObj.dataList = [];
          this.$refs.searchTable.searchConditions = "";
          this.$refs.searchTable.init();
        });
      }
    },
  },
  mounted() {
    //表格高度
    this.$nextTick(() => {
      this.tableHeight =
        document.getElementsByClassName("home_content")[0].clientHeight - 209;
    });
    this.init();
  },
  created() {},
  methods: {
    // input值改变是触发
    sumchange(val) {
      this.inputFirst = val;
    },
    saveChange(val) {
      this.inputBai = val;
    },
    init() {
      //获取左上角数据
      let data = {
        modelKeyword: "",
      };
      Target.ListEnableModel(data).then((res) => {
        res.data.modelList.forEach((item, index) => {
          //获取当前视图
          if (index === 0) {
            this.$set(item, "isdefault", "1");
            this.$set(item, "isCurrent", true);
          } else {
            this.$set(item, "isdefault", "");
            this.$set(item, "isCurrent", false);
          }
          this.$set(item, "label", item.name);
        });
        this.modelId = res.data.modelList[0].id;
        this.modelName = res.data.modelList[0].name;
        this.Mmodelid = res.data.modelList[0].modelid; //模型id
        this.targetobj = res.data.modelList[0].obj; //目标类型
        this.modelList = res.data.modelList;
        this.period = res.data.modelList[0].period;
        this.descriptionName = res.data.modelList[0].description;
        //新建展示
        if (this.period == "year") {
          this.newYear = true;
          this.newMonth = false;
          this.newQuarter = false;
        } else if (this.period == "month ") {
          this.newYear = false;
          this.newMonth = true;
          this.newQuarter = false;
        } else if (this.period == "quarter") {
          this.newYear = false;
          this.newMonth = false;
          this.newQuarter = true;
        }
        //获取目标类型数据
        this.typeOptions = [
          {
            name:
              this.$i18n.t("label.category.1") +
              this.$i18n.t("label.htyj.mubiao"), //'全部目标',
            id: "1",
          },
          {
            name:
              this.$i18n.t("label.sso.summary") +
              this.$i18n.t("label.htyj.mubiao"), //'汇总目标',
            id: "2",
          },
          {
            name:
              res.data.modelList[0].label + this.$i18n.t("label.htyj.mubiao"), //'目标'
            id: "3",
          },
        ];
        if (this.targetobj == "201100000006339JMl6w") {
          this.analog = this.$t("label.quote.choseproduct"); //选择产品
          this.tarPrincipal = true;
          this.targetSzName = "product";
          this.newBtnName = this.$t("label.new.Product.Goals"); //新建产品目标
        }
        if (this.targetobj == "account") {
          this.analog = this.$t("label.select.customer"); //选择客户
          this.tarPrincipal = true;
          this.targetSzName = "account";
          this.newBtnName = this.$t("label_new_customer_target"); //新建客户目标
        }
        if (this.targetobj == "user") {
          this.analog = this.$t("label.select.personnel"); //选择人员
          this.tarPrincipal = false;
          this.targetSzName = "ccuser";
          this.newBtnName = this.$t("label.new.crew.target");
        }
        this.typeValue = this.typeOptions[0].name;
        // 展示目标列表
        this.getTable();
        this.moudleDetail();
      });
      //获取年度数据
      let date = new Date();
      let year = date.getFullYear();
      let yearbefore = date.getFullYear() - 1;
      let yearafter = date.getFullYear() + 1;
      this.targetYear = year;
      this.selectYear = year;
      this.yearOptions = [
        {
          id: 1,
          label: yearbefore,
        },
        {
          id: 2,
          label: year,
        },
        {
          id: 3,
          label: yearafter,
        },
      ];
    },
    moudleDetail() {
      let param = {
        modelid: this.Mmodelid,
      };
      TargetModel.ModelDetail(param).then((res) => {
        res.data.result.forEach((item) => {
          this.objconid = item.objconid;
        });
      });
    },
    // 展示颜色设置
    showColorSetting() {
      let param = {
        modelid: this.Mmodelid,
      };
      Target.showColorSetting(param).then((res) => {
        if (res.data[0]) {
          this.inputFirst = res.data[0].startnum;
          this.inputBai = res.data[0].endnum;
          this.colorId = res.data[0].id;
          this.issetting = res.data[0].issetting === "true" ? true : false;
        }
      });
    },
    //  保存颜色设置
    saveColorSetting(val) {
      let data = {};
      if (val === "save") {
        data = {
          modelid: this.Mmodelid,
          id: this.colorId,
          startNum: this.inputFirst,
          endNum: this.inputBai,
          isSetting: this.issetting === true ? "true" : "false",
        };
      } else {
        data = {
          modelid: this.Mmodelid,
          id: this.colorId,
          startNum: "60",
          endNum: "80",
          isSetting: "false",
        };
      }
      Target.saveColorSetting(data).then((res) => {
        if (res.result === true) {
          this.$message({
            showClose: true,
            type: "success",
            //   设置成功
            message: this.$i18n.t("label_set_successfully"),
          });
          this.getConclude();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            //   设置失败
            message: this.$i18n.t("label_setup_failed"),
          });
        }
      });
    },
    showset() {
      //设置弹框的显示
      this.$refs.setting.doShow();
    },
    hideset() {
      //设置弹框的隐藏
      this.$refs.setting.doClose();
    },
    //处理表头数据
    tableFun(labelArr) {
      if (this.period === "year") {
        labelArr.forEach((item) => {
          this.tableAttr.push({
            nameLabel: this.targetYear,
            schemefieldName: item.Api,
          });
        });
      } else if (this.period == "month ") {
        labelArr.forEach((item) => {
          this.tableAttr.push({
            nameLabel: item.Api + this.$i18n.t("label.login.access.month"), //'月'
            schemefieldName: item.Api,
          });
        });
      } else if (this.period == "quarter") {
        labelArr.forEach((item) => {
          if (item.Api === "1") {
            this.tableAttr.push({
              nameLabel: this.$i18n.t("label.first.quarter"), //'第一季度'
              schemefieldName: item.Api,
            });
          }
          if (item.Api === "2") {
            this.tableAttr.push({
              nameLabel: this.$i18n.t("label.two.quarter"), //'第二季度'
              schemefieldName: item.Api,
            });
          }
          if (item.Api === "3") {
            this.tableAttr.push({
              nameLabel: this.$i18n.t("label.three.quarter"), //'第三季度'
              schemefieldName: item.Api,
            });
          }
          if (item.Api === "4") {
            this.tableAttr.push({
              nameLabel: this.$i18n.t("label.four.quarter"), //'第四季度'
              schemefieldName: item.Api,
            });
          }
        });
      }
    },
    // 展示目标列表
    getTable() {
      this.pictLoading = true;
      // 初始化前三列表头
      this.tableAttr = JSON.parse(JSON.stringify(this.defaultTableAttr));
      let data = {
        modelid: this.Mmodelid,
        name: this.targetSzName,
        page: this.page,
        pageSize: this.pageSize,
        year: this.selectYear,
        targetowner: this.targetowner,
        targetKeyword: this.targetKeyword,
        type: this.selectType,
      };
      //目标列表
      Target.ShowTargetList(data).then((res) => {
        // 表头
        if (res.data.label && res.data.label.length > 0) {
          this.tableFun(res.data.label);
        } else {
          this.tableAttr = [];
        }
        // 数据
        res.data.target.forEach((item) => {
          item.periodList.forEach((ite) => {
            for (let key in ite) {
              this.$set(item, key, ite[key].split("-")[1]);
            }
          });
        });
        // this.page++;
        this.tableAttr.forEach((item, idx) => {
          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
          item.schemefieldType = item.type;
        });
        this.$refs.tablePanel.changeTableAttr(this.tableAttr);
        this.pageObj.dataList = res.data.target;
        //表格数据
        this.pictLoading = false;
        this.moudleDetail();
      });
    },
    //展示达成类别
    getConclude() {
      this.pictLoading = true;
      // 初始化前三列表头
      this.tableAttr = JSON.parse(JSON.stringify(this.defaultTableAttr));
      let data = {
        modelid: this.Mmodelid,
        page: this.page,
        name: this.targetSzName,
        pageSize: this.pageSize,
        year: this.selectYear,
        targetowner: this.targetowner,
        targetKeyword: this.targetKeyword,
        type: this.selectType,
        fresh: this.ifFresh,
      };
      //达成列表
      Target.TargetConclude(data).then((res) => {
        // 表头
        if (res.data.label && res.data.label.length > 0) {
          this.tableFun(res.data.label);
        } else {
          this.tableAttr = [];
        }
        // 数据
        res.data.target.forEach((item) => {
          item.periodList.forEach((ite) => {
            for (let key in ite) {
              this.$set(
                item,
                key,
                ite[key].split("-")[1] + "," + ite.realAchieve.split("-")[1]
              );
            }
          });
        });

        // this.page++;
        this.tableAttr.forEach((item, idx) => {
          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
          item.schemefieldType = item.type;
        });
        this.$refs.tablePanel.changeTableAttr(this.tableAttr);
        this.reachList = res.data.target;
        this.pageObj.dataList = res.data.target;
        //表格数据
        this.pictLoading = false;
        this.showColorSetting();
      });
    },
    //目标筛选
    targetChange() {
      this.page = 1;
      this.getTargetTable();
    },
    //负责人筛选
    ownerSerch() {
      this.page = 1;
      this.getTargetTable();
    },
    //打开新建客户目标弹框
    createcustarr() {
      this.createcustar = true;
    },
    //关闭新建客户目标弹框
    custTargetCancel() {
      this.createcustar = false;
    },
    //新建目标
    custTargetConfirm(newtarget, saveAddNew) {
      this.pictLoading = true;
      let params = {
        modelid: this.Mmodelid,
        target: newtarget.target,
        obj: this.targetobj,
        targetowner: newtarget.targetowner,
        ownername: newtarget.ownername,
        ownertype: newtarget.ownertype,
        year: newtarget.year,
        jsonStr: newtarget.jsonStr,
      };
      Target.SaveTarget(params).then(() => {
        this.page = 1;
        this.custTargetCancel();
        this.getTargetTable();
        if (saveAddNew) {
          setTimeout(() => {
            this.createcustar = true;
          }, 1000);
        }
        // TODO: 如果是保存并新建，再次打开新建弹窗
      });
    },
    //打开新建汇总目标弹框
    dialogVisiblee() {
      this.dialogVisible = true;
    },
    //关闭新建汇总目标弹框
    collectTargetCancel() {
      this.dialogVisible = false;
    },
    //新建汇总目标
    collectConfirm(newtarget, saveAddNew) {
      let params = {
        modelid: this.Mmodelid,
        targetname: newtarget.targetName,
        target: newtarget.target,
        obj: this.targetobj,
        targetowner: newtarget.targetowner,
        ownername: newtarget.ownername,
        ownertype: newtarget.ownertype,
        year: newtarget.year,
        jsonStr: newtarget.jsonStr,
      };
      Target.saveSummary(params).then(() => {
        this.createcustar = false;
        this.page = 1;
        this.collectTargetCancel();
        this.getTargetTable();
        if (saveAddNew) {
          setTimeout(() => {
            this.dialogVisiblee();
          }, 1000);
        }
      });
    },
    //根据目标类型筛选
    typeOptionsChange() {
      if (this.typeValue == 1) {
        this.selectType = "";
      }
      if (this.typeValue == 2) {
        this.selectType = "summary";
      }
      if (this.typeValue == 3) {
        this.selectType = "object";
      }
      this.page = 1;
      this.getTargetTable();
    },
    //根据年份筛选
    targetYearChange() {
      if (this.targetYear === 1) {
        this.selectYear = this.yearOptions[0].label;
      }
      if (this.targetYear === 2) {
        this.selectYear = this.yearOptions[1].label;
      }
      if (this.targetYear === 3) {
        this.selectYear = this.yearOptions[2].label;
      }
      this.page = 1;
      this.getTargetTable();
    },
    changeView(view) {
      this.modilId = view.modelid;
      this.issetting = false;
      this.modelList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.modelName = item.name;
          this.modelid = item.modelid; //模型id
          this.targetobj = item.obj; //目标类型
          this.period = item.period;
          this.descriptionName = item.description;
          this.Mmodelid = item.modelid;
          if (this.period == "year") {
            this.newYear = true;
            this.newMonth = false;
            this.newQuarter = false;
          } else if (this.period == "month ") {
            this.newYear = false;
            this.newMonth = true;
            this.newQuarter = false;
          } else if (this.period == "quarter") {
            this.newYear = false;
            this.newMonth = false;
            this.newQuarter = true;
          }
          //判断当前模型基本对象
          if (this.targetobj == "201100000006339JMl6w") {
            this.analog = this.$i18n.t("label.quote.choseproduct"); //选择产品
            this.tarPrincipal = true;
            this.targetSzName = "product";
            this.newBtnName = this.$i18n.t("label.new.Product.Goals"); //新建产品目标
          }
          if (this.targetobj == "account") {
            this.analog = this.$i18n.t("label.select.customer"); //选择客户
            this.tarPrincipal = true;
            this.targetSzName = "account";
            this.newBtnName = this.$i18n.t("label_new_customer_target"); //新建客户目标
          }
          if (this.targetobj == "user") {
            this.analog = this.$i18n.t("label.select.personnel"); //选择人员
            this.tarPrincipal = false;
            this.targetSzName = "ccuser";
            this.newBtnName = this.$i18n.t("label.new.crew.target");
          }
          item.isCurrent = true;
        } else {
          item.isCurrent = false;
        }
      });
      this.page = 1;
      this.getTargetTable();
    },
    append() {
      if (!this.noDataTip) {
        // this.radioGroupBtn();
      }
    },
    //表格选中数据
    onchange(val) {
      this.tableCheckedObjectArr = val;
    },
    //刷新
    refreshTable() {
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;
      this.page = 1;
      this.getConclude();
    },
    //判断当前是目标||达成调用对应接口
    getTargetTable() {
      if (this.tabPosition === "target") {
        this.getTable();
      } else {
        this.ifFresh = "true";
        this.getConclude();
      }
    },
    seting() {
      this.setting = "sett2";
    },
    sett() {
      this.setting = "sett";
    },
    radioGroupBtn(e) {
      // target
      this.present = e;
      this.pageObj.dataList = [];
      // 目标
      if (e === "target") {
        this.page = 1;
        this.getTable();
      } else if (e === "reach") {
        this.page = 1;
        this.getConclude();
      }
    },
    // clear() {
    //   this.targetowner = ''
    //   this.page = 1
    //   this.getTargetTable()
    // },
    // ownerEvent() {
    //   this.targetowner = this.targetowner
    //   this.page = 1
    //   this.getTargetTable()
    // },
    // changeSelect(row) {
    //   this.targetowner = row.data.id
    //   this.showSearchTable = false
    //   this.$refs.searchTable.pageObj.dataList = []
    //   this.page = 1
    //   this.getTargetTable()
    // },
    changeSelect() {},
    //批量删除
    batchDelete() {
      if (this.tableCheckedObjectArr.length == 0) {
        this.$message.warning(this.$i18n.t("lable.opppdt.selPdt.leastone"));
      } else {
        this.closeDialogVisible = true;
      }
    }, //确定删除
    confirmDel() {
      let targetidArr = [];
      let targetArr = [];
      let modelArr = [];
      this.tableCheckedObjectArr.map((item)=> {
        targetidArr.push(item.targetid)
        targetArr.push(item.target)
        modelArr.push(item.model)
      })
      Target.DeleteTarget({
        id: targetidArr.join(","),
        target: targetArr.join(","),
        modelid: modelArr[0],
      }).then(() => {
        this.pageNum = 1;
        this.page = 1;
        this.closeDialogVisible = false;
        this.$message.success(this.$i18n.t("label.ems.delete.success"));
        this.getTable();
        this.tableCheckedObjectArr = [];
      });
    },
    handleClose() {
      this.closeDialogVisible = false;
    },
    //问号 改变icon
    mouseEnterMark() {
      this.img = "helpp-blue";
    },
    mouseLeaveMark() {
      this.img = "helpp";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input--mini .el-input__inner {
  margin-left: 10px;
}
span {
  display: inline-block;
}
ol li {
  list-style-type: decimal;
  line-height: 20px;
  font-size: 14px;
  height: 26px;
}
ul {
  height: 52px;
}
.colorBai {
  line-height: 22px;
  margin-top: 20px;
  li {
    float: left;
    ::v-deep .el-input__inner {
      padding: 0;
      text-align: center;
    }
    ::v-deep .el-input {
      margin-right: 15px;
      width: 40px;
      height: 26px;
    }
    svg {
      width: 20px;
      margin-top: 5px;
    }
  }
}
.progress-color {
  width: 100%;
  height: 6px;
  text-align: center;
  span {
    width: 30%;
    height: 100%;
    margin-right: 3%;
  }
  .lf {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    background: #e2403c;
  }
  .center_col {
    background: #006dcc;
  }
  .rf {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #4bca82;
  }
}
.content_butts {
  margin: 40px 0 0 120px;
}
.el-table--enable-row-transition {
  width: 100%;
  height: 735px !important;
}
// ::v-deep .el-input--suffix{
//   width: 200px !important;
// }
::v-deep .el-popover__reference {
  width: 32px;
  height: 28px;
  position: relative;
}
.el-scrollbar__wrap {
  overflow: scroll;
  /* height: 100%; */
  height: 55px;
}
::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  /* height: 100%; */
  height: 55px;
}
.el-dialog span {
  font-family: SourceHanSansCN-Regular;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #006dcc;
}
// ::v-deep .el-dialog__header {
//   border:1px solid #DDDBDA;
// }
.dgup {
  min-width: 60%;
}
.dgcenter {
  margin-top: 20px;
  width: 100%;
}
.d1,
.d2,
.d3 {
  width: 100%;
  // background:blue;
  border: 1px solid #dddbda;
  display: flex;
  justify-content: space-around;
}
.d1 {
  border-bottom: none;
}
.d1 > dl {
  margin: 20px 0;
}
.d2 > dl {
  margin: 20px 0;
}
.d3 > dl {
  margin: 20px 0;
}
.d2 {
  border-bottom: none;
}
span {
  // margin: 0 10px;
  line-height: 30px;
}
.label_s {
  margin-left: 10px;
  font-size: 12px;
}
::v-deep .searchBox {
  margin-right: 10px;
  width: 156px !important;
  .el-input__inner {
    height: 30px;
    width: 156px;
  }
}
.button {
  color: #006dcc;
  background-color: #fff;
  margin: 0;
  border: 1px solid #dedcda;
}
.button:hover {
  color: none;
}
.el-button--small {
  border-radius: 3px;
}
.el-input__inner {
  border-left: none;
}

::v-deep .form {
  .el-table {
    .el-table__header-wrapper {
      .el-table__header {
        .has-gutter {
          tr {
            th {
              border-left: 1px solid #dedcda;
              color: #3e3e3c;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-table th.is-leaf {
  border-bottom: 0px;
}
::v-deep .cell {
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
}
::v-deep .tit {
  font-size: 12px !important;
}
::v-deep .is-leaf {
  color: red !important;
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;

  &:hover {
    background: #f5f7fa;
  }
}
::v-deep .el-select .el-input__inner {
  font-size: 12px;
  margin-left: 10px;
}
.targetmain {
  height: 97%;
  background-color: #eee;
  border-radius: 4px;
  margin: 10px;
  overflow-y: hidden;
  // overflow-x: scroll;
  .main_no {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    .content {
      width: 600px;
      height: 250px;
      position: absolute;
      margin: auto;
      text-align: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      svg {
        width: 299px;
        height: 164px;
        margin: 0 auto;
      }
    }
  }
  .main {
    width: 100%;
    // height: 100%;
    background-color: #fff;
    .header {
      height: 13%;
      width: 100%;
      padding: 10px;
      background-color: #f3f2f2;
      border: 1px solid #dddbda;
      // border-bottom: none;
      .form {
        // overflow:scroll;
        height: 800px;
        width: 800px;
      }
      .headerup {
        display: flex;
        justify-content: space-between;
        .hupleft {
          float: left;
        }
      }
      .headerup,
      .headerdown {
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .hdownleft {
        margin-top: 10px;
        float: left;
        min-width: 70%;
        display: flex;
        justify-content: flex-start;
        ::v-deep .el-radio-button__inner {
          width: 55px;
          height: 30px;
          display: inline-block;
          line-height: 30px;
          padding: 0;
          text-align: center;
        }
      }
      .hdownright {
        margin-top: 5px;
        .btnRef {
          ::v-deep .tips_box {
            width: 30px;
            height: 30px;
            position: relative;
            svg {
              position: absolute;
              left: 9px;
              top: 7px;
              width: 14px;
              height: 14px;
            }
          }
        }
        .btnRight {
          span {
            width: 30px;
            height: 30px;
            display: inline-block;
            width: 30px;
            top: 3px;
            position: relative;
            .setning {
              border-left: 0px;
              margin: 0;
              padding: 0;
              width: 30px;
              height: 30px;
              top: 5px;
              i {
                position: relative;
                top: 1px;
                color: #888;
                svg {
                  width: 14px;
                  height: 14px;
                  position: relative;
                  bottom: 1px;
                }
              }
            }
          }
        }
      }
      .refreshBtn {
        margin: 0 0 0 10px;
        border-radius: 3px;
        width: 30px !important;
        height: 30px !important;
        padding: 0;
        position: relative;
        i {
          position: absolute;
          right: 8px;
          top: 8px;
          color: #888;
        }
      }
      .hupright {
        display: flex;
        height: 30px;
        span {
          ::v-deep span {
            margin-top: -1px !important;
          }
          margin: 0 !important;
        }
        .customerBtn {
          border-radius: 3px 0 0 3px;
          border-right: none;
        }
        .summaryBtn {
          border-radius: 0;
          border-right: none;
        }
        .lastBtn {
          border-radius: 0 3px 3px 0;
        }
      }
      .searchBox {
        width: 156px;
        height: 30px;
      }
    }
    .no_table {
      position: relative;
      .content {
        width: 600px;
        height: 250px;
        position: absolute;
        margin: auto;
        text-align: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        svg {
          width: 299px;
          height: 164px;
          margin: 0 auto;
        }
        div {
          font-size: 14px;
          color: #888888;
          margin-top: 30px;
        }
      }
    }
    ::v-deep .text-center {
      .el-table--border {
        th {
          border-right: 1px solid #dedcda;
          .tableHeadField {
            span {
              font-size: 14px;
              color: #3e3e3c !important;
            }
          }
        }
      }
    }
  }
}
ul {
  margin: 0;
  .textItem {
    font-size: 12px;
    color: #666;
    cursor: pointer;
    line-height: 25px;
    padding: 0 12px;
    margin: 0 -12px;

    &:hover {
      background: #f3f2f2;
      color: #006dcc;
    }
  }
}
</style>
<style>
.btnRl .el-checkbox__label,
.btnRl .colorBai ul li {
  font-size: 14px;
  color: #080707;
}
.btnRl .el-input__inner {
  padding: 0;
  margin: -2px 15px 0 0 !important;
}
</style>
