<!-- 新建客户目标弹框 -->
<template>
  <div>
    <el-dialog
      :title="$t('label.new.target')"
      :visible="createcustar"
      :modal-append-to-body="false"
      :before-close="custTargetCancel"
      width="1120px"
    >
      <el-form
        :inline="true"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="145px"
        class="demo-ruleForm"
      >
        <el-form-item :label="analog" prop="khinput">
          <el-input
            :placeholder="$t('label.emailtocloudcc.select1')"
            style="width: 350px"
            size="mini"
            v-model="ruleForm.khinput"
            @click.native="remoteSearch"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('label.person.target.responsible')"
          v-if="tarPrincipal"
          style="margin-left: 30px"
        >
          <el-select
            v-model="princvalue"
            :placeholder="$t('label.emailtocloudcc.select1')"
            size="mini"
            style="width: 95px; border-radius: 3px 0 0 3px; border-right: none"
            @change.native="changeFzrinput"
          >
            <el-option
              v-for="item in principal"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            :placeholder="$t('label.searchs')"
            style="width: 183px"
            size="mini"
            v-model="ruleForm.fzrinput"
            @click.native="remoteSearchh"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.select.year')">
          <el-select
            v-model="Yearvalue"
            :placeholder="$t('label.emailtocloudcc.select1')"
            size="mini"
            @change="changeYear"
          >
            <el-option
              v-for="item in optionYear"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('label.Batch.input')">
          <el-input
            style="width: 163px"
            size="mini"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            v-model="allinput"
          ></el-input>
          <el-button
            size="small"
            style="background: #006dcc; color: #fff; margin-left: 50px"
            @click="copytarget"
          >
            {{ $t("label.copy.target") }}
          </el-button>
        </el-form-item>
      </el-form>
      <everyYear
        ref="newYear"
        v-if="newYear"
        :mryear="mryear"
        :allinput="allinput"
      />
      <everyMonth ref="newMonth" v-if="newMonth" :allinput="allinput" />
      <everyQuarter ref="newQuarter" v-if="newQuarter" :allinput="allinput" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="custTargetCancel">{{
          $t("label.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="custTargetConfirm('ruleForm', 'saveAddNew')"
          >{{ $t("label.saveandnew") }}</el-button
        >
        <el-button type="primary" @click="custTargetConfirm('ruleForm')">{{
          $t("label.save")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 搜索对象设置 -->
    <el-dialog
      :title="$t('label.stdashbord.xsld.business.ajssx')"
      :visible.sync="showOpenRole"
      width="500px"
    >
      <shuttle-box
        :role-data="roleData"
        :role-name="roleName"
        @deliverLength="deliverLength"
        @cancel="changeSearch"
      ></shuttle-box>
    </el-dialog>
    <!-- 查找字段设置 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :objconid="objconid"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          :relevantmodelid="Mmodelid"
          :prefix="relevantPrefix"
          :isShowClearBtn="isShowClearBtn"
          :searchKeyWord="searchKeyWord"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import shuttleBox from "@/components/Tree/choseRole.vue";
import roleTreeMixin from "@/mixin/roleTree.js";
import everyMonth from "../everyMonth.vue";
import everyYear from "../everyYear.vue";
import everyQuarter from "../everyQuarter.vue";
import Bus from "@/mixin/bus.js";
import SearchTable from "@/components/Form/search-table.vue";
import * as Target from "../api.js";
export default {
  mixins: [roleTreeMixin],
  watch: {
    showSearchTable(newvalue) {
      if (newvalue) {
        this.$nextTick(() => {
          this.$refs.searchTable.page = 1;
          this.$refs.searchTable.pageObj.dataList = [];
          this.$refs.searchTable.searchConditions = "";
          this.$refs.searchTable.init();
        });
      }
    },
    relevantmodelid() {
      this.relevantmodelid = this.Mmodelid;
    },
    createcustar(newvalue) {
      if (newvalue) {
        this.$nextTick(() => {
          this.$refs["ruleForm"].resetFields();
          this.clearTable();
        });
      }
    },
  },
  props: {
    createcustar: {
      type: Boolean,
      default: false,
    },
    newMonth: {
      type: Boolean,
      default: false,
    },
    newQuarter: {
      type: Boolean,
      default: false,
    },
    newYear: {
      type: Boolean,
      default: false,
    },
    Mmodelid: {
      type: String,
      default: "",
    },
    analog: {
      type: String,
      default: "",
    },
    targetobj: {
      type: String,
      default: "",
    },
    tarPrincipal: {
      type: Boolean,
      default: true,
    },
    objconid: {
      type: String,
      default: "",
    },
  },
  components: {
    SearchTable,
    everyMonth,
    everyYear,
    everyQuarter,
    shuttleBox,
  },
  data() {
    return {
      isShowClearBtn: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      relevantmodelid: "",
      showOpenRole: false,
      searchKeyWord: "",
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      showSearchTable: false,
      allinput: "",
      Yearvalue: "",
      princvalue: "",
      ruleForm: {
        khinput: "",
        fzrinput: "",
      },
      principal: [
        {
          id: 1,
          value: "选项1",
          label: this.$i18n.t("chatter.feed.menutype.user"),
        },
        {
          id: 2,
          value: "选项2",
          label: this.$i18n.t("label.tabpage.rolez"),
        },
      ], //目标负责人
      optionYear: [
        {
          id: 1,
          value: "选项1",
          label: "",
        },
        {
          id: 2,
          value: "选项2",
          label: "",
        },
        {
          id: 3,
          value: "选项3",
          label: "",
        },
      ],
      mryear: "",
      newjsonstr: null,
      rules: {
        khinput: [
          {
            required: true,
            message: this.$i18n.t("lable.product.please.enter"),
            trigger: "change",
          },
        ],
        fzrinput: [
          {
            required: true,
            message: this.$i18n.t("label.emailtocloudcc.select1"),
            trigger: "change",
          },
        ],
      },
      newtarget: {
        target: "",
        year: "",
        targetowner: "",
        ownername: "",
        ownertype: "",
        jsonStr: null,
      },
      roleName: "",
      //保存角色的id
      selectedRole: [],
      selectedRoles: "",
    };
  },
  mounted() {
    this.optionYearr();
    this.princvalue = 1;
    Bus.$on("windowResize", this.ebFn);
  },
  beforeDestroy(){
    Bus.$off("windowResize", this.ebFn);
  },
  methods: {
    ebFn(offsetHeight){
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    getResult() {
      if (this.selectedRole.length != 0) {
        this.selectedRoles = this.selectedRole.toString();
      } else {
        this.selectedRoles = "";
      }
      //根据id查人员
      let params = {
        roleid: this.selectedRoles,
      };
      Target.assignUserByCondition(params).then((res) => {
        this.newtarget.ownername = "";
        this.newtarget.targetowner = "";
        res.data.unadded.forEach((item) => {
          //拼接人员name和人员id
          this.newtarget.ownername += "," + item.name;
          this.newtarget.targetowner += "," + item.id;
          //去掉多余逗号
        });
        this.newtarget.ownername = this.newtarget.ownername.substr(1);
        this.newtarget.targetowner = this.newtarget.targetowner.substr(1);
        this.ruleForm.fzrinput = this.newtarget.ownername;
      });
    },
    //根据类型设置查找字段搜索列表
    remoteSearch() {
      if (this.targetobj == "201100000006339JMl6w") {
        this.relevantObjId = "201100000006339JMl6w";
        this.relevantPrefix = "009";
      }
      if (this.targetobj == "account") {
        this.relevantObjId = "account";
        this.relevantPrefix = "001";
      }
      if (this.targetobj == "user") {
        this.relevantObjId = "user";
        this.relevantPrefix = "005";
      }
      this.showSearchTable = true;
      this.$refs.searchTable.pageObj.dataList = [];
    },
    remoteSearchh() {
      if (this.princvalue === 1) {
        this.relevantObjId = "user";
        this.relevantPrefix = "005";
        this.showSearchTable = true;
        this.newtarget.ownertype = "user";
        this.$refs.searchTable.pageObj.dataList = [];
      } else {
        this.newtarget.ownertype = "role";
        this.getRoleLists();
        this.showOpenRole = true;
      }
    },
    //收起弹出框
    changeSearch() {
      this.showOpenRole = false;
    },
    //根据当前年设置optionYear的默认值
    optionYearr() {
      let data = new Date();
      let year = data.getFullYear();
      let yearbefore = data.getFullYear() - 1;
      let yearafter = data.getFullYear() + 1;
      this.Yearvalue = year.toString();
      this.optionYear[0].label = yearbefore.toString();
      this.optionYear[1].label = year.toString();
      this.optionYear[2].label = yearafter.toString();
      this.mryear = this.Yearvalue;
    },
    changeYear() {
      this.mryear = this.Yearvalue;
    },
    //取消
    custTargetCancel() {
      this.$emit("custTargetCancel");
      // this.createcustar = false;
      // this.$refs.searchTable.pageObj.dataList = [];
    },
    // 获取当前年月份天数
    getDay(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    //确认
    custTargetConfirm(ruleForm, saveAddNew) {
      this.newtarget.year = this.Yearvalue;
      //根据年||月||季判断传值
      if (this.newMonth == true) {
        this.newjsonstr = [
          {
            begindate: `${this.mryear}-01-01`,
            enddate: `${this.mryear}-01-31`,
            data: [{ 1: this.$refs.newMonth.newMonthVal.Jan }],
          },
          {
            begindate: `${this.mryear}-02-01`,
            enddate: `${this.mryear}-02-${this.getDay(this.mryear,2)}`,
            data: [{ 2: this.$refs.newMonth.newMonthVal.Feb }],
          },
          {
            begindate: `${this.mryear}-03-01`,
            enddate: `${this.mryear}-03-31`,
            data: [{ 3: this.$refs.newMonth.newMonthVal.Mar }],
          },
          {
            begindate: `${this.mryear}-04-01`,
            enddate: `${this.mryear}-04-30`,
            data: [{ 4: this.$refs.newMonth.newMonthVal.Apr }],
          },
          {
            begindate: `${this.mryear}-05-01`,
            enddate: `${this.mryear}-05-31`,
            data: [{ 5: this.$refs.newMonth.newMonthVal.May }],
          },
          {
            begindate: `${this.mryear}-06-01`,
            enddate: `${this.mryear}-06-30`,
            data: [{ 6: this.$refs.newMonth.newMonthVal.Jun }],
          },
          {
            begindate: `${this.mryear}-07-01`,
            enddate: `${this.mryear}-07-31`,
            data: [{ 7: this.$refs.newMonth.newMonthVal.Jul }],
          },
          {
            begindate: `${this.mryear}-08-01`,
            enddate: `${this.mryear}-08-30`,
            data: [{ 8: this.$refs.newMonth.newMonthVal.Aug }],
          },
          {
            begindate: `${this.mryear}-09-01`,
            enddate: `${this.mryear}-09-30`,
            data: [{ 9: this.$refs.newMonth.newMonthVal.Sept }],
          },
          {
            begindate: `${this.mryear}-10-01`,
            enddate: `${this.mryear}-10-31`,
            data: [{ 10: this.$refs.newMonth.newMonthVal.Oct }],
          },
          {
            begindate: `${this.mryear}-11-01`,
            enddate: `${this.mryear}-11-30`,
            data: [{ 11: this.$refs.newMonth.newMonthVal.Nov }],
          },
          {
            begindate: `${this.mryear}-12-01`,
            enddate: `${this.mryear}-12-31`,
            data: [{ 12: this.$refs.newMonth.newMonthVal.Dec }],
          },
        ];
      }
      if (this.newQuarter == true) {
        this.newjsonstr = [
          {
            begindate: `${this.mryear}-01-01`,
            enddate: `${this.mryear}-03-31`,
            data: [{ 1: this.$refs.newQuarter.newQuarterVal.oneQuarter }],
          },
          {
            begindate: `${this.mryear}-04-01`,
            enddate: `${this.mryear}-6-30`,
            data: [{ 2: this.$refs.newQuarter.newQuarterVal.twoQuarter }],
          },
          {
            begindate: `${this.mryear}-07-01`,
            enddate: `${this.mryear}-09-30`,
            data: [{ 3: this.$refs.newQuarter.newQuarterVal.threeQuarter }],
          },
          {
            begindate: `${this.mryear}-10-01`,
            enddate: `${this.mryear}-12-31`,
            data: [{ 4: this.$refs.newQuarter.newQuarterVal.fourQuarter }],
          },
        ];
      }
      if (this.newYear == true) {
        this.newjsonstr = [
          {
            begindate: `${this.mryear}-01-01`,
            enddate: `${this.mryear}-12-31`,
            data: [],
          },
        ];
        let obj = {};
        obj[`1`] = this.$refs.newYear.nian;
        this.newjsonstr[0].data.push(obj);
      }
      this.newtarget.jsonStr = JSON.stringify(this.newjsonstr);
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$emit("custTargetConfirm", this.newtarget, saveAddNew);
        }
      });
    },
    //查找字段选择
    changeSelect(row) {
      if (this.relevantPrefix == "001") {
        this.ruleForm.khinput = row.data.name;
        this.newtarget.target = row.data.id;
      }
      if (this.relevantPrefix == "009") {
        this.ruleForm.khinput = row.data.name;
        this.newtarget.target = row.data.id;
      }
      if (this.relevantPrefix == "005" && this.tarPrincipal == true) {
        this.ruleForm.fzrinput = row.data.name;
        this.newtarget.targetowner = row.data.id;
        this.newtarget.ownername = row.data.name;
      }
      if (this.relevantPrefix == "005" && this.tarPrincipal == false) {
        this.ruleForm.khinput = row.data.name;
        this.newtarget.target = row.data.id;
        this.newtarget.ownername = row.data.name;
      }
      this.onshowSearchTable();
    },
    onshowSearchTable() {
      this.showSearchTable = false;
      this.$refs.searchTable.pageObj.dataList = [];
    },
    deliverLength(result) {
      if (result.length > 0) {
        this.roleName = "hasRole";
        this.selectedRole = result;
        this.ownerName = "";
        this.ownerid = "";
      } else {
        this.roleName = "";
        this.selectedRole = [];
      }
      this.getResult();
    },
    //复制目标值
    copytarget() {
      if (this.newMonth == true) {
        let value = this.allinput
        this.allinput = value;
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        months.forEach((item) => {
          this.$refs.newMonth.newMonthVal[item] = this.allinput;
        });
      }
      if (this.newQuarter == true) {
        let newQuarter = [
          "oneQuarter",
          "twoQuarter",
          "threeQuarter",
          "fourQuarter",
        ];
        newQuarter.forEach((item) => {
          this.$refs.newQuarter.newQuarterVal[item] = this.allinput;
        });
      }
      if (this.newYear == true) {
        this.$refs.newYear.nian = this.allinput;
      }
    },
    //清空
    clearTable() {
      this.ruleForm.khinput = "";
      this.ruleForm.fzrinput = "";
      this.allinput = "";
      if (this.newMonth == true) {
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        months.forEach((item) => {
          this.$refs.newMonth.newMonthVal[item] = "0";
        });
      }
      if (this.newQuarter == true) {
        let newQuarter = [
          "oneQuarter",
          "twoQuarter",
          "threeQuarter",
          "fourQuarter",
        ];
        newQuarter.forEach((item) => {
          this.$refs.newQuarter.newQuarterVal[item] = "0";
        });
      }
      if (this.newYear == true) {
        this.$refs.newYear.nian = "0";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dgdown {
  width: 1048px;
  padding-top: 20px;
  height: 272px;
  border: 1px solid #dddbda 100%;
  .d2 {
    width: 1048px;
    height: 90.2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #dddbda;
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
    }
  }
  .d3 {
    width: 1048px;
    height: 90.2px;
    display: flex;

    align-items: center;
    justify-content: space-around;
    border: 1px solid #dddbda;
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
    }
  }
  ::v-deep .el-form-item__label {
    padding: 0;
  }
  .d1 {
    width: 1048px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    height: 90.2px;
    border: 1px solid #dddbda;
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
    }
  }
}
</style>
