<template>
  <div class="dgdown" ref="newMonth">
    <div class="d1">
      <dl>
        <dt>{{$t('january')}}</dt>
        <dd>
          <el-input v-model="newMonthVal.Jan" onkeyup="value=value.replace(/[^\d]/g,'')">
          </el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('february')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Feb"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('march')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Mar"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('april')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Apr"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
    </div>
    <div class="d2">
      <dl>
        <dt>{{$t('mary')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.May"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('june')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Jun"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('july')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Jul"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('augest')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Aug"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
    </div>
    <div class="d3">
      <dl>
        <dt>{{$t('september')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Sept"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('october')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Oct"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('november')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Nov"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
      <dl>
        <dt>{{$t('december')}}</dt>
        <dd>
          <el-input
            v-model="newMonthVal.Dec"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newMonthVal: {
        Jan: "0",
        Feb: "0",
        Mar: "0",
        Apr: "0",
        May: "0",
        Jun: "0",
        Jul: "0",
        Aug: "0",
        Sept: "0",
        Oct: "0",
        Nov: "0",
        Dec: "0",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.dgdown {
  width: 1048px;
  padding-top: 20px;
  height: 272px;
  border: 1px solid #dddbda 100%;
  .d2 {
    width: 1048px;
    height: 90.2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #dddbda;
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
    }
  }
  .d3 {
    width: 1048px;
    height: 90.2px;
    display: flex;

    align-items: center;
    justify-content: space-around;
    border: 1px solid #dddbda;
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
    }
  }
  .d1 {
    width: 1048px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    height: 90.2px;
    border: 1px solid #dddbda;
    ::v-deep .el-input__inner {
      width: 136px;
      height: 28px;
    }
  }
}
</style>